/*===================
    Box Styles
====================*/

.wrong-box {
    border: 1px solid $theme-color;
    padding: 0.6em;
    text-align: center;
    background: rgba(249, 0, 77, 0.2);
    margin-top: 1em;
}

.success-box {
    border: 1px solid $success-color;
    padding: 0.6em;
    text-align: center;
    background: rgba(0, 238, 107, .2);
    margin-top: 1em;
}

.light-box {
    padding: 0.6em;
    text-align: center;
    background: rgba(249, 0, 77, 0.0);
    margin-top: 1em;
}