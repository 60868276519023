/*===================
Button Area 
====================*/

$height-input: 3.5em;

input, select {
    &.input-text {
        height: $height-input;
        padding-right: 1em;
        background-color: rgba($color: #ffffff, $alpha: 1.0);
        border: 0;
    }
    &.input-required {
        border: 2px solid #f9004d;
    }
    &::-webkit-input-placeholder {
        color: #7d7d7d;
    }
}

.link {
    &.link-underline {
        color: #f9004d;
        text-decoration: underline;
    }
    &.link-no-underline {
        color: #f9004d;
    }
}


button {
    &.input-button {
        height: $height-input;
        border-radius: 0 !important;
        font-size: 12x !important;
        text-transform: uppercase;
        width: 100%;
        color: #ffffff;
        letter-spacing: 0.2px;
        background-color: rgba($color: #f9004d, $alpha: 0.8);
        border: 0;
    }
    &:hover {
        background-color: rgba($color: #f9004d, $alpha: 0.4);;
        border: none;
    }
}

label {
    &.label-input {
        width: 100%;
    }
}

.no-space {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.right-space {
    padding-right: 6px !important;
    padding-left: 0 !important;
    padding-bottom: 3px !important;
}

::-webkit-input-placeholder {
    color: #000000;
 }